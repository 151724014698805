import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PrivacyPage from "./pages/PrivacyPage";
import ContactPage from "./pages/ContactPage";

const Routes = ({ setIsHomePage }) => {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/privacy" component={PrivacyPage} />
      <Route exact path="/contact" component={ContactPage} />
    </Switch>
  );
};

export default Routes;
