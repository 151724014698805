import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBContainer,
} from "mdbreact";
import logo from "../assets/logo-removebg-preview.png";

class Header extends Component {
  state = {
    collapsed: false,
  };

  handleTogglerClick = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed,
    });
  };

  render() {
    const { collapsed } = this.state;
    const navStyle = { marginTop: "0rem" };

    const overlay = (
      <div
        id='sidenav-overlay'
        style={{ backgroundColor: "transparent" }}
        onClick={this.handleTogglerClick}
      />
    );
    return (
      <>
        <MDBNavbar
          color='primary-color-dark'
          dark
          //light
          expand='md'
          fixed='top'
          scrolling
          //transparent
          style={navStyle}
        >
          <MDBContainer>
            <MDBNavbarBrand href='/'>
              <img
                src={logo}
                style={{ height: "1.5rem", width: "1.5rem" }}
                alt='logo'
                className='mr-2'
              />
              {/* <strong className="white-text">Dominionmandate Radio</strong> */}
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={this.handleTogglerClick} />
            <MDBCollapse isOpen={collapsed} navbar>
              <MDBNavbarNav left>
                <MDBNavItem>
                  <MDBNavLink to='/' onClick={this.handleTogglerClick}>
                    Home
                  </MDBNavLink>
                </MDBNavItem>
                {/* <MDBNavItem>
                  <MDBNavLink to="/live" onClick={this.handleTogglerClick}>
                    Live
                  </MDBNavLink>
                </MDBNavItem> */}
                <MDBNavItem>
                  <MDBNavLink to='/privacy' onClick={this.handleTogglerClick}>
                    Policy
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to='/contact' onClick={this.handleTogglerClick}>
                    Contact Us
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNavbarNav>
              {/* <MDBNavbarNav right>
                <MDBNavItem>
                  <MDBFormInline waves>
                    <div className="md-form my-0">
                      <input
                        className="form-control mr-sm-2"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </div>
                  </MDBFormInline>
                </MDBNavItem>
              </MDBNavbarNav> */}
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
        {collapsed && overlay}
      </>
    );
  }
}

export default Header;
