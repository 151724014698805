import React from "react";
import { MDBRow, MDBCol, MDBIcon, MDBBtn, MDBView } from "mdbreact";
import TwitterBase from "../components/TwitterBase";
import landImage from "../assets/twitImage.jpeg";
import Advert from "../components/Advert";
import googlePlay from "../assets/google-play-badge.png";
import appleTv from "../assets/appleTVWhite.svg";
import applePlay from "../assets/appleEng.svg";
import Preview from "../components/Preview";

const HomePage = () => {
  const url = new URLSearchParams(window.location.search);
  const location = url.get("layout");
  const isWebView = location && location === "webview";
  return (
    <>
      <MDBView className={isWebView ? "" : "homePage"}>
        <img
          src={landImage}
          alt='landing'
          className='img-fluid '
          style={{ width: "100vw" }}
        />

        {!isWebView ? (
          <MDBRow>
            <MDBCol md='12' className='btn-container'>
              <div>
                <img
                  src={applePlay}
                  alt='apple'
                  style={{
                    display: "inline-block",
                    height: "30px",
                    cursor: "pointer",
                    marginLeft: "7px",
                  }}
                  className='img-fluid'
                />

                <img
                  src={appleTv}
                  alt='appleTV'
                  style={{
                    display: "inline-block",
                    height: "30px",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  className='img-fluid'
                />

                <a
                  href='https://play.google.com/store/apps/details?id=com.icreo.dominionmandateradio'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={googlePlay}
                    alt='googlePlay'
                    style={{
                      width: "100px",
                      height: "43px",
                      display: "inline-block",
                      cursor: "pointer",
                      margin: "0",
                    }}
                    className='img-fluid ml-1'
                  />
                </a>

                <MDBBtn
                  // href="/"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  outline
                  color='black'
                  size='sm'
                  className='rounded text-bold'
                >
                  <MDBIcon fab icon='android' className='green-text' />
                  Andriod TV
                </MDBBtn>
              </div>
              <div className='social-container'>
                <a
                  href='https://web.facebook.com/pg/dominionmandateradiointl/posts/?ref=page_internal'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <MDBIcon
                    fab
                    icon='facebook-square'
                    size='2x'
                    className='blue-text ml-3'
                  />
                </a>
                <a
                  href='https://twitter.com/dominion_radio'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <MDBIcon
                    fab
                    icon='twitter'
                    size='2x'
                    className='ml-3 cyan-text'
                  />
                </a>
                <a
                  href='https://www.instagram.com/dominionmandateradio/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <MDBIcon
                    fab
                    icon='instagram'
                    size='2x'
                    className='ml-3 pink-text'
                  />
                </a>
                <a
                  href='https://api.whatsapp.com/send?phone=2348187896537'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <MDBIcon
                    fab
                    icon='whatsapp'
                    size='2x'
                    className='ml-3 green-text'
                  />
                </a>

                <a
                  href='https://www.youtube.com/results?search_query=dominion+city'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <MDBIcon
                    fab
                    icon='youtube'
                    size='2x'
                    className='ml-3 red-text'
                  />
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        ) : null}

        <div className='mt-4' style={{ width: "99%", margin: "auto" }}>
          <MDBRow>
            <Preview isWebView={isWebView} />
            {!isWebView ? <Advert /> : null}
            {!isWebView ? <TwitterBase /> : null}
          </MDBRow>
        </div>
      </MDBView>
    </>
  );
};

export default HomePage;
