import React, { useState, useEffect, useRef } from "react";
import "./player.style.css";
import { MDBIcon } from "mdbreact";
import ShareModal from "./ShareModal";

function Player({ playingData }) {
  const [value, setRange] = useState(1);
  const [playing, setPlaying] = useState(false);
  const [isShare, setIsShare] = useState(false);

  const audio = useRef("audio_tag");

  const toggleShare = () => {
    setIsShare(!isShare);
  };

  const togglePlaying = () => {
    if (playing) {
      setPlaying(false);
      audio.current.pause();
      audio.current.load();
    } else {
      setPlaying(true);

      audio.current.play();
    }
  };

  const toggleAudio = () =>
    audio.current.paused ? audio.current.play() : audio.current.pause();

  useEffect(() => {
    audio.current.volume = value;
    if (playing) {
      toggleAudio();
    }

    // eslint-disable-next-line
  }, []);

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + " ..." : str;
  };

  const handleVolume = (q) => {
    setRange(q);
    audio.current.volume = q;
  };

  const url = new URLSearchParams(window.location.search);
  const location = url.get("layout");
  const gmn = url.get("gmn");

  const isWebView = location && location === "webview";

  return (
    <div className='controls'>
      <audio
        src={"https://www.radioking.com/play/dominion-mandate-radio/348040"}
        ref={audio}
      />

      <div className='vlme'>
        <span className='volum'>
          <i className='fas fa-volume-down'></i>
        </span>
        <input
          value={Math.round(value * 100)}
          type='range'
          name='volBar'
          id='volBar'
          onChange={(e) => handleVolume(e.target.value / 100)}
        />
      </div>

      <div className='musicControls'>
        <span
          className={gmn ? "play webview" : "play"}
          onClick={() => {
            togglePlaying();
          }}
        >
          {!playing ? <MDBIcon icon='play' /> : <MDBIcon icon='pause' />}
        </span>
      </div>
      <span className='ml-2 title text-center title-container'>
        {playingData.title ? (
          <span>
            {truncate(playingData.title, 19)} <br></br>
            {playingData.artist && (
              <p className='text-center'>{`( ${playingData.artist} )`}</p>
            )}
          </span>
        ) : (
          <p>Dominoin Mandate Radio</p>
        )}
      </span>
      {isWebView ? null : (
        <div className='share-icon'>
          <MDBIcon
            icon='share-square'
            style={{ cursor: "pointer" }}
            onClick={toggleShare}
          />
        </div>
      )}

      <ShareModal
        isOpen={isShare}
        toggle={toggleShare}
        title={playingData.title}
      />
    </div>
  );
}

export default Player;
