import React from "react";
import { MDBCol } from "mdbreact";
import phoneImg from "../assets/phoneWithImage.png";

const Advert = () => {
  return (
    <MDBCol md='4' style={{ marginBottom: "5rem" }}>
      <img src={phoneImg} alt='phone' className='img-fluid' />
      <div className='text-center'>
        <iframe
          id='AudioDedication'
          src='https://widget.radioking.io/audio-shoutouts/?id=dominion-mandate-radio'
          allow='microphone'
          width='265'
          height='365'
          style={{ borderRadius: "8px" }}
          frameBorder='0'
          title='shoutOut'
        ></iframe>
      </div>
    </MDBCol>
  );
};

export default Advert;
