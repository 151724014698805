import React, { useEffect } from "react";
import { MDBCol } from "mdbreact";

const Preview = ({ isWebView }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.radioking.io/current-track/build/script.min.js";
    script.type = "text/javascript";
    document.getElementsByClassName("playing-now")[0].appendChild(script);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widget.radioking.io/played-tracks/build/script.min.js";
    script.type = "text/javascript";
    document.getElementsByClassName("history")[0].appendChild(script);
  }, []);

  return (
    <MDBCol md='4' style={isWebView ? { marginBottom: "8rem" } : {}}>
      <div className='mb-3 playing-now'>
        <h4 className='text-center'>Playing Now</h4>
        <div
          id='rk-current-track-widget'
          data-id='dominion-mandate-radio'
          data-buy='0'
        ></div>
      </div>
      <h4 className='text-center'>History</h4>
      <div className='mb-3 history'>
        <div
          id='rk-played-tracks-widget'
          data-id='dominion-mandate-radio'
          data-count='4'
          data-date='1'
          data-buy='1'
          // style={{ maxWidth: "100%" }}
        ></div>
      </div>
    </MDBCol>
  );
};

export default Preview;
