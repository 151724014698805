import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBInput,
  MDBAlert,
} from "mdbreact";
import logo from "../assets/logo-removebg-preview.png";

const ContactPage = () => {
  const [status, setStatus] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const notification = () => {
    if (isSubmitted && status === "SUCCESS") {
      return (
        <MDBAlert color="success" dismiss>
          Message sent successfully, we will respond to you.
        </MDBAlert>
      );
    } else if (isSubmitted && status === "ERROR") {
      return (
        <MDBAlert color="danger" dismiss>
          Error sending message, Please fill all fields
        </MDBAlert>
      );
    } else return null;
  };

  // form submission
  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;

    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
        setIsSubmitted(true);
      } else {
        setStatus("ERROR");
        setIsSubmitted(true);
      }
    };
    xhr.send(data);
  };

  return (
    <MDBContainer className="mt-5 text-center contact-page">
      <img
        src={logo}
        alt="logo"
        className="img-fluid text-center"
        style={{ height: "7.5rem", width: "7.5rem" }}
      />
      <h2 className="h1-responsive font-weight-bold text-center my-4">
        Contact Us
      </h2>
      <p className="text-center w-responsive mx-auto pb-5">
        Get accross with your message
      </p>
      <MDBRow>
        <MDBCol md="9" className="md-0 mb-5">
          <form
            action="https://formspree.io/xaypblov"
            method="POST"
            onSubmit={submitForm}
          >
            <MDBRow>
              <MDBCol md="6">
                <div className="md-form mb-0">
                  <MDBInput
                    type="text"
                    id="contact-name"
                    label="Your name"
                    name="name"
                  />
                </div>
              </MDBCol>
              <MDBCol md="6">
                <div className="md-form mb-0">
                  <MDBInput
                    type="text"
                    id="contact-email"
                    label="Your email"
                    name="email"
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="12">
                <div className="md-form mb-0">
                  <MDBInput type="text" id="contact-subject" label="Subject" />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="12">
                <div className="md-form mb-0">
                  <MDBInput
                    type="textarea"
                    id="contact-message"
                    label="Your message"
                    name="message"
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <div className="text-center text-md-left">
              <MDBBtn color="primary" size="md" type="submit">
                Send
              </MDBBtn>
            </div>
            <div className="mt-1">{notification()}</div>
          </form>
        </MDBCol>
        <MDBCol md="3" className="">
          <ul className="list-unstyled mb-0">
            <li>
              <MDBIcon icon="map-marker-alt" size="2x" className="blue-text" />
              <p>
                Dominion Mandate Radio <br />
                Golden Heart Place, <br /> km 22, Lekki-Epe Expressway,
                Lekki, Lagos <br /> Nigeria
              </p>
            </li>
            <li>
              <MDBIcon icon="phone" size="2x" className="blue-text mt-4" />
              <p>
                +234-903-637-0546 <br /> +234-803-495-4566 <br />{" "}
                +234-803-437-0332
              </p>
            </li>
            <li>
              <MDBIcon icon="envelope" size="2x" className="blue-text mt-4" />
              <p>mail@dominionmandateradio.com</p>
            </li>
          </ul>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ContactPage;
