import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";

import "./App.css";

import Header from "./components/Header";
import Routes from "./Routes";
import Player from "./components/Player/Player";

function App() {
  const [playingData, setData] = useState({});

  const url = new URLSearchParams(window.location.search);
  const location = url.get("layout");

  useEffect(() => {
    axios
      .get(
        "https://api.radioking.io/widget/radio/dominion-mandate-radio/track/current"
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(
      () =>
        axios
          .get(
            "https://api.radioking.io/widget/radio/dominion-mandate-radio/track/current"
          )
          .then((res) => {
            setData(res.data);
          })
          .catch((error) => {
            console.log(error);
          }),
      50000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Router>
        <div>
          {location && location === "webview" ? null : <Header />}
          <main>
            <Routes />
          </main>
        </div>
        {/* <MDBFooter color="primary-color-dark" className="">
          <p className="footer-copyright mb-0 py-3 text-center">
            &copy; {new Date().getFullYear()} Copyright: Dominion Mandate Radio
          </p>
        </MDBFooter> */}
      </Router>

      <Player playingData={playingData} />
    </div>
  );
}

export default App;
