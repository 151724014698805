import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBIcon,
  MDBContainer,
} from "mdbreact";

const ShareModal = ({ isOpen, toggle, title }) => {
  const baseUrl = "https://dominionmandateradio.com/";
  return (
    <MDBModal
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      className="modal-dialog-centered"
    >
      <MDBModalHeader toggle={toggle} className="blue-text text-capitalize">
        share on
      </MDBModalHeader>
      <MDBModalBody>
        <MDBContainer className="text-center">
          <a
            href={
              `https://www.facebook.com/sharer/sharer.php?u=` + baseUrl + title
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <MDBIcon
              fab
              icon="facebook-square"
              size="2x"
              className="blue-text"
            />
          </a>
          {/* <a href="#!">
            <MDBIcon
              fab
              icon="whatsapp"
              size="2x"
              className="ml-3 green-text"
            />
          </a> */}

          {/* <a href="https://www.youtube.com/results?search_query=dominion+city">
            <MDBIcon fab icon="youtube" size="2x" className="ml-3 red-text" />
          </a> */}
          <a
            href={
              `https://www.twitter.com/share?url=` +
              baseUrl +
              "&text=" +
              title +
              "&via" +
              "twitterhandle"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <MDBIcon fab icon="twitter" size="2x" className="ml-3 cyan-text" />
          </a>
          <a
            href="https://www.instagram.com/dominionmandateradio/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MDBIcon
              fab
              icon="instagram"
              size="2x"
              className="ml-3 pink-text"
            />
          </a>
        </MDBContainer>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="danger" size="sm" onClick={toggle} className="rounded">
          Close
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
};

export default ShareModal;
